<template>
  <!-- TODO: `min-h-screen` to be removed -->
  <div
    class="font-montserrat bg-secondary overflow-hidden subpixel-antialiased"
  >
    <Navbar></Navbar>
    <router-view />
    <Footer></Footer>
    <ScrollToTop></ScrollToTop>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    ScrollToTop,
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
