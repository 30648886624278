import { createRouter, createWebHistory } from "vue-router";

function loadView(view) {
  return () => import(`@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: loadView("Home"),
  },
  {
    path: "/story",
    name: "Story",
    component: loadView("Story"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: loadView("Privacy"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: loadView("Terms"),
  },
  {
    path: "/:notFound(.*)",
    name: "PageNotFound",
    component: loadView("PageNotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
