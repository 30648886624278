import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueSmoothScroll from "vue3-smooth-scroll";

// Tailwind CSS
import "./assets/css/tailwind.css";

import VueScrollTo from "vue-scrollto";

createApp(App)
  .use(store)
  .use(router)
  .use(VueSmoothScroll)
  .use(VueScrollTo)
  .mount("#app");
