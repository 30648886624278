<template>
  <a
    href="#hero"
    id="btn"
    v-smooth-scroll
    class="hidden fixed justify-center items-center bottom-10 right-10 w-12 h-12 bg-primary shadow-lg rounded-full cursor-pointer z-50 hover:bg-ternary transition duration-400 border border-gray-400 hover:opacity-75"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      width="1.25em"
      height="1.25em"
      style="
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        margin-top: -5px;
      "
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1664 1280"
    >
      <path
        d="M1619 1075l-166 165q-19 19-45 19t-45-19L832 709l-531 531q-19 19-45 19t-45-19L45 1075q-19-19-19-45.5T45 984l742-741q19-19 45-19t45 19l742 741q19 19 19 45.5t-19 45.5z"
        fill="#f3f3f3"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "ScrollToTop",
  data() {
    return {
      scrollHeight: 400,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.showButton);
  },
  methods: {
    showButton: function () {
      const btn = document.getElementById("btn");

      if (
        document.body.scrollTop > this.scrollHeight ||
        document.documentElement.scrollTop > this.scrollHeight
      ) {
        btn.style.display = "inline-flex";
      } else {
        btn.style.display = "none";
      }
    },
  },
};
</script>

<style scoped></style>
